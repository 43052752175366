import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
	AlertDialogCloseBtn,
} from '@/components/ui/alert-dialog';

import Icon from '@/components/atoms/icon';

/**
 * @typedef {Object} DialogFooterProps
 * @property {string} cancelBtnText
 * @property {string} actionBtnText
 * @property {(params:{actionBtnText: string})=> React.ReactNode} renderCustomAction
 * @property {(params:{actionBtnText: string})=> React.ReactNode} renderCustomCancel
 * @property {boolean} disabled
 */

/**
 * @name DialogFooter
 * @description Renders the footer of the dialog with the cancel and action buttons
 * @param {DialogFooterProps} props
 * @returns {React.JSX.Element}
 * @example
 * <DialogFooter cancelBtnText="Cancel" actionBtnText="Save" />
 */

function DialogFooter({ cancelClassName, actionClassName, className, cancelBtnText, actionBtnText, renderCustomAction, renderCustomCancel, disabled }) {
	return (
		<AlertDialogFooter className={className}>
			{renderCustomCancel ? (
				renderCustomCancel({ cancelBtnText })
			) : cancelBtnText ? (
				<AlertDialogCancel className={cancelClassName} disabled={disabled}>
					{cancelBtnText}
				</AlertDialogCancel>
			) : null}
			{renderCustomAction ? (
				renderCustomAction({ actionBtnText })
			) : actionBtnText ? (
				<AlertDialogAction className={actionClassName} disabled={disabled}>
					{actionBtnText}
				</AlertDialogAction>
			) : null}
		</AlertDialogFooter>
	);
}

/**
 * @typedef {Object} ModalProps
 * @property {string} title
 * @property {string} contents
 * @property {string} cancelBtnText
 * @property {string} actionBtnText
 * @property {string} icon
 * @property {string} iconClassName
 * @property {boolean} hideFooter
 * @property {React.ReactNode | (params:{DialogFooter: typeof DialogFooter}) =>React.ReactNode} children
 **/

/**
 * @name Modal
 * @description Renders a modal component
 * @param {import("@radix-ui/react-alert-dialog").DialogProps & ModalProps} props
 * @returns {React.JSX.Element}
 * @example
 * <Modal
 *   title="Profile Information"
 *   contents="Update your account's profile information and email address."
 *   actionBtnText="Save"
 *   cancelBtnText="Cancel"
 *   hideFooter
 * >
 * Hello world
 * </Modal>
 */

const Modal = ({
	trigger,
	title,
	contents,
	cancelBtnText,
	actionBtnText,
	icon,
	iconClassName,
	titleClassName,
	hideHeader,
	hideFooter,
	children,
	closeDisabled,
	isContentNarrow,
	isWithoutOverlay,
	isFixedToTop,
	contentClasses,
	overlayClasses,
	customCloseBtnComponent,
	closeBtnClasses,
	...props
}) => {
	const isTriggerString = typeof trigger === 'string';
	return (
		<AlertDialog {...props}>
			<AlertDialogTrigger asChild={!isTriggerString}>{trigger}</AlertDialogTrigger>
			<AlertDialogContent
				isContentNarrow={isContentNarrow}
				isWithoutOverlay={isWithoutOverlay}
				isFixedToTop={isFixedToTop}
				className={contentClasses}
				overlayClasses={overlayClasses}
			>
				{!hideHeader && (
					<AlertDialogHeader>
						<div className="flex justify-between gap-4">
							{icon ? (
								<div className="flex items-center gap-4">
									<Icon name={icon} className={iconClassName} />
									<AlertDialogTitle className={cn('first-letter:uppercase normal-case', titleClassName)}>{title}</AlertDialogTitle>
								</div>
							) : (
								<AlertDialogTitle className={cn('first-letter:uppercase normal-case', titleClassName)}>{title}</AlertDialogTitle>
							)}
							{customCloseBtnComponent ? (
								customCloseBtnComponent()
							) : (
								<AlertDialogCloseBtn className={cn('shrink-0', closeBtnClasses && closeBtnClasses)} disabled={closeDisabled}>
									<Icon name="close" />
								</AlertDialogCloseBtn>
							)}
						</div>
						<AlertDialogDescription>{contents}</AlertDialogDescription>
					</AlertDialogHeader>
				)}

				<div className="flex flex-col gap-6 md:gap-8">
					{typeof children === 'function' ? children({ DialogFooter }) : children}

					{hideFooter ? null : <DialogFooter cancelBtnText={cancelBtnText} actionBtnText={actionBtnText} disabled={closeDisabled} />}
				</div>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export default Modal;

Modal.propTypes = {
	trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	title: PropTypes.string.isRequired,
	contents: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	cancelBtnText: PropTypes.string,
	actionBtnText: PropTypes.string,
	modalContents: PropTypes.object,
	icon: PropTypes.string,
	iconClassName: PropTypes.string,
	hideFooter: PropTypes.bool,
	closeDisabled: PropTypes.bool,
	isContentNarrow: PropTypes.bool,
};
Modal.defaultProps = {
	trigger: null,
	title: null,
	contents: null,
	modalContents: null,
	icon: null,
	iconClassName: null,
	hideFooter: false,
	hideHeader: false,
	closeDisabled: false,
	isContentNarrow: false,
};
