import * as React from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { headingVariants } from '@/components/ui/heading';
import { textVariants } from '@/components/ui/text';

const AlertDialog = AlertDialogPrimitive.Root;

const AlertDialogTrigger = AlertDialogPrimitive.Trigger;

const AlertDialogPortal = ({ className, ...props }) => <AlertDialogPrimitive.Portal className={cn(className)} {...props} />;
AlertDialogPortal.displayName = AlertDialogPrimitive.Portal.displayName;

const AlertDialogOverlay = React.forwardRef(({ className, children, ...props }, ref) => (
	<AlertDialogPrimitive.Overlay
		className={cn(
			'fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
			className
		)}
		{...props}
		ref={ref}
	/>
));
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;

const AlertDialogContent = React.forwardRef(({ className, overlayClasses, isContentNarrow, isWithoutOverlay, isFixedToTop, ...props }, ref) => (
	<AlertDialogPortal>
		{!isWithoutOverlay && <AlertDialogOverlay className={overlayClasses} />}
		<AlertDialogPrimitive.Content
			ref={ref}
			className={cn(
				'fixed left-[50%] top-[50%] z-50  translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
				// Note: custom styles
				'flex flex-col gap-6 md:gap-8 p-8 border-formOutline rounded-2xl shadow-large-drop-shadow max-h-9/10 overflow-y-scroll',
				// Note: custom max-width and left & right margin (16px * 2)
				'w-[calc(100%-2rem)]',
				isContentNarrow ? 'max-w-eis-408' : 'max-w-eis-790',
				isFixedToTop ? 'top-[8%] translate-y-[-18%]' : '',
				className
			)}
			{...props}
		/>
	</AlertDialogPortal>
));
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;

const AlertDialogHeader = ({ className, ...props }) => <div className={cn('flex flex-col space-y-2', className)} {...props} />;
AlertDialogHeader.displayName = 'AlertDialogHeader';

const AlertDialogFooter = ({ className, ...props }) => (
	<div className={cn('flex flex-col-reverse md:flex-row md:justify-end md:space-x-2', className)} {...props} />
);
AlertDialogFooter.displayName = 'AlertDialogFooter';

const AlertDialogTitle = React.forwardRef(({ className, ...props }, ref) => (
	<AlertDialogPrimitive.Title
		ref={ref}
		className={cn(
			// Note: use custom headingVariants from heading.js
			headingVariants({ type: 'h4', colour: 'eis-black' }),
			className
		)}
		{...props}
	/>
));
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;

const AlertDialogDescription = React.forwardRef(({ className, ...props }, ref) => (
	<AlertDialogPrimitive.Description
		ref={ref}
		className={cn(
			// Note: use custom textVariants from text.js
			textVariants({ type: 'small', variant: 'normal' }),
			className
		)}
		{...props}
	/>
));
AlertDialogDescription.displayName = AlertDialogPrimitive.Description.displayName;

const AlertDialogAction = React.forwardRef(({ className, ...props }, ref) => (
	<AlertDialogPrimitive.Action ref={ref} className={cn(buttonVariants({ variant: 'primary', size: 'default' }), className)} {...props} />
));
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;

const AlertDialogCancel = React.forwardRef(({ className, ...props }, ref) => (
	<AlertDialogPrimitive.Cancel ref={ref} className={cn(buttonVariants({ variant: 'secondary', size: 'default' }), 'mt-4 md:mt-0', className)} {...props} />
));
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;

// Note: a custom element for "X" close button
const AlertDialogCloseBtn = React.forwardRef(({ className, ...props }, ref) => (
	// Note: the style should be the same as the "Circle Icon" in the "Button" atom
	<AlertDialogPrimitive.Cancel ref={ref} className={cn(buttonVariants({ variant: 'circle-icon', size: 'circle-icon' }), className)} {...props} />
));
AlertDialogCloseBtn.displayName = AlertDialogPrimitive.Cancel.displayName;

export {
	AlertDialog,
	AlertDialogTrigger,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogFooter,
	AlertDialogTitle,
	AlertDialogDescription,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogCloseBtn,
};
